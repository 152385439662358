
import React from 'react';
const Home = () => {
    
return (
      <>
        <h1 style={{color:"green"}}> 
            Welcome to GeeksForGeeks</h1>
      </>
  )
};
  
export default Home;


